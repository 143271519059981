<template>
	<div class="works-area ptb-100">
		<div class="container">
			<div class="row justify-content-center">
        <!--
				<div class="col-lg-4 col-sm-6">
					<div class="work-card">
						<img src="../../assets/images/blue_banner.png" alt="image" width="100">
						<div class="content">
              <span>
                  <router-link to="/jobs/csa">
                      Support / CFTools Cloud
                  </router-link>
              </span>
							<h3>
								<router-link to="/jobs/csa" class="w-100">
									Customer Success Agent (m/w/d)
									<small class="w-100">
										<small class="float-start">
											<i class="fas fa-clock"/>
											Werkstudent / Teilzeit
										</small>
										<small class="float-end">
											Full-Remote
											<i class="fas fa-search-location"/>
										</small>
									</small>
								</router-link>
							</h3>

							<router-link class="work-btn" to="/jobs/csa">
								Mehr Erfahren
							</router-link>
						</div>
					</div>
				</div>
				-->
				<!--
				<div class="col-lg-4 col-sm-6">
					<div class="work-card">
						<img src="../../assets/images/blue_banner.png" alt="image" width="100">
						<div class="content">
              <span>
                  <router-link to="/jobs/fullstack-cfcloud">
                      Engineering / CFTools Cloud
                  </router-link>
              </span>
							<h3>
								<router-link to="/jobs/fullstack-cfcloud" class="w-100">
									Softwareentwickler (m/w/d)
									<small class="w-100">
										<small class="float-start">
											<i class="fas fa-clock"/>
											Vollzeit / Teilzeit
										</small>
										<small class="float-end">
											Full-Remote
											<i class="fas fa-search-location"/>
										</small>
									</small>
								</router-link>
							</h3>

							<a class="work-btn" href="https://de.indeed.com/job/softwareentwickler-mwd-7342305311a4e701" target="_blank">
								Mehr Erfahren auf Indeed
							</a>
						</div>
					</div>
				</div>
				-->
				<!--
				<div class="col-lg-4 col-sm-6">
					<div class="work-card">
						<img src="../../assets/images/blue_banner.png" alt="image" width="100">
						<div class="content">
              <span>
                  <router-link to="/jobs/fullstack-cfcloud">
                      Engineering / CFTools Cloud
                  </router-link>
              </span>
							<h3>
								<router-link to="/jobs/fullstack-cfcloud" class="w-100">
									Webentwickler (m/w/d)
									<small class="w-100">
										<small class="float-start">
											<i class="fas fa-clock"/>
											Vollzeit / Teilzeit
										</small>
										<small class="float-end">
											Full-Remote
											<i class="fas fa-search-location"/>
										</small>
									</small>
								</router-link>
							</h3>

							<a class="work-btn" href="https://de.indeed.com/job/webentwickler-mwd-32040d1911999b9b" target="_blank">
								Mehr Erfahren auf Indeed
							</a>
						</div>
					</div>
				</div>
				-->


				<div class="col-lg-4 col-sm-6">
					<div class="work-card">
						<img src="../../assets/images/blue_banner.png" alt="image" width="100">
						<div class="content">
              <span>
                  <router-link to="/jobs/frontend-engineer">
                      Engineering / CFTools Cloud
                  </router-link>
              </span>
							<h3>
								<router-link to="/jobs/frontend-engineer" class="w-100">
									Frontend Engineer (m/w/d)
									<small class="w-100">
										<small class="float-start">
											<i class="fas fa-clock"/>
											Vollzeit / Teilzeit / Werkstudent
										</small>
										<small class="float-end">
											Full-Remote
											<i class="fas fa-search-location"/>
										</small>
									</small>
								</router-link>
							</h3>

							<router-link class="work-btn" to="/jobs/frontend-engineer">
								Mehr Erfahren
							</router-link>
						</div>
					</div>
				</div>


				<!--
				<div class="col-lg-4 col-sm-6">
					<div class="work-card">
						<img src="../../assets/images/blue_banner.png" alt="image" width="100">
						<div class="content">
              <span>
                  <router-link to="/jobs/ws-cfcloud">
                      Engineering / CFTools Cloud
                  </router-link>
              </span>
							<h3>
								<router-link to="/jobs/ws-cfcloud" class="w-100">
									Werkstudent Engineering (m/w/d)
									<small class="w-100">
										<small class="float-start">
											<i class="fas fa-clock"/>
											Werkstudent in Teilzeit
										</small>
										<small class="float-end">
											Full-Remote
											<i class="fas fa-search-location"/>
										</small>
									</small>
								</router-link>
							</h3>

							<router-link class="work-btn" to="/jobs/ws-cfcloud">
								Mehr Erfahren
							</router-link>
						</div>
					</div>
				</div>
				-->


				<!-- Pagination -->
				<div class="col-lg-12 col-sm-12">
					<div class="pagination-area">
                        <span class="prev page-numbers">
                          <i class="fas fa-angle-double-left"></i>
                        </span>
						<span class="page-numbers current" aria-current="page">1</span>
						<span class="next page-numbers">
                          <i class="fas fa-angle-double-right"></i>
                        </span>
					</div>
				</div>
				<div class="col-lg-12 col-sm-12 text-center">
					<h3 class="mt-4">Nichts passendes dabei?</h3>
					<div class="single-info-box">
						<a href="mailto:info@cftools.software?subject=Bewerbung - Initiativbewerbung" target="_blank" class="default-btn">Initiativbewerbung<span></span></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Listing'
}
</script>