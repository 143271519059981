
<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Offene Stellen" />
        <Listing />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../../components/Layout/Navbar'
import PageTitle from '../../components/Common/PageTitle'
import Listing from './List'
import Footer from '../../components/Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        Listing,
        Footer,
    }
}
</script>